/* global tw */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
// import Link from 'gatsby-link';

const Wrapper = styled.a`
  width: 100%;
  ${tw('shadow-lg relative no-underline')};
  // img {
  //   transition: all 0.6s ease-in-out !important;
  //   filter: grayscale(0);
  // }
  // &:hover {
  //   img {
  //     transform: scale(1.15);
  //     filter: grayscale(0);
  //   }
  // }
  // @media (min-width: 900px) {
  //   img {
  //     filter: grayscale(1);
  //   }
  // }
`;

const Video = styled.div`
  ${tw('w-full h-full flex-wrap relative overflow-hidden')};
  @media (min-height: 600px) {
    width: 100%;
    min-height: 400px;
  }
`;

const IFrame = styled.iframe`
  ${tw('z-50 flex-wrap w-full h-full')}
  @media (min-height: 600px) {
    width: 100%;
    min-height: 400px;
  }
`;

const PhotoCard = ({ src }) => (
  <Wrapper>
    <Video>
      <IFrame src={src} frameBorder="0"
              allow="autoplay; encrypted-media; gyroscope; accelerometer; encrypted-media; picture-in-picture; allowscriptaccess" allowFullScreen></IFrame>
    </Video>
  </Wrapper>
);

export default PhotoCard;

PhotoCard.propTypes = {
  src: PropTypes.string.isRequired,
};
