/* global tw */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { Parallax, ParallaxLayer } from 'react-spring';
// eslint-disable-next-line
import { Spring } from 'react-spring'
import 'typeface-roboto-condensed'
import 'typeface-eczar';
// import 'typeface-open-sans';

import SEO from '../components/SEO';
import SVG from '../components/SVG';
import ProjectCard from '../components/ProjectCard';
import PhotoCard from '../components/PhotoCard';
import Contact from '../components/Contact';
import ContactForm from '../components/ContactForm'
import YoutubeCard from '../components/YoutubeCard'
import { rotate, UpDown, UpDownWide, waveAnimation } from '../styles/animations';
import { hidden } from '../styles/utils';
import { colors } from '../../tailwind';
// import triangle from '../images/triangle.svg';
import triangle from '../images/logo-white.svg';
import avatar from '../images/avatar.png';
import '../styles/global';

const Divider = styled(ParallaxLayer)`
  ${tw('absolute w-full h-full')};
  background: ${props => props.bg};
  svg {
    fill: ${props => props.fill};
  }
  clip-path: ${props => props.clipPath};
`;

const DividerMiddle = styled(Divider)`
  clip-path: polygon(0 15%, 100% 25%, 100% 85%, 0 75%);
`;

const Content = styled(ParallaxLayer)`
  ${tw('p-6 md:p-12 lg:p-24 justify-center items-center flex z-50')};
`;

const Hero = styled.div`
  ${tw('w-full xl:w-3/4')};
`;

const Inner = styled.div`
  ${tw('w-full xxl:w-2/3 text-center lg:text-left')};
`;

const BigTitle = styled.h1`
  ${tw('text-4xl lg:text-6xl font-serif text-white mb-6 tracking-wide')};
  text-shadow: 0 5px 35px rgba(255, 255, 255, 0.15);
`;

const Title = styled.h1`
  ${tw('text-4xl lg:text-5xl font-serif text-white mb-8 tracking-wide relative inline-block')};
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  &:before {
    content: '';
    width: 40px;
    height: 40px;
    background: url(${triangle});
    position: absolute;
    background-size: 40px;
    animation: ${rotate} 4s linear infinite;
    left: -50px;
    top: -3px;
  }
`;

const Subtitle = styled.p`
  ${tw('text-2xl lg:text-4xl font-sans text-white mt-8 xxl:w-3/4')};
  text-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
`;

const ProjectsWrapper = styled.div`
  ${tw('flex flex-wrap justify-between mb-8')};
  display: grid;
  grid-gap: 4rem;
  row-gap: 10vh;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 1200px) {
    grid-gap: 3rem;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }
`;

const YoutubeWrapper = styled.div`
  ${tw('flex flex-wrap justify-between mb-8')};
  display: grid;
  grid-gap: 4rem;
  row-gap: 10vh;
  grid-template-columns: 1fr;
  @media (max-width: 1200px) {
    grid-gap: 3rem;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }
`;

// eslint-disable-next-line
const ProjectsSubtitle = styled.h2`
  ${tw('text-3xl text-white font-serif tracking-wide mt-8 mb-2')};
  &:before {
    left: -60px;
  }
`;

const WaveWrapper = styled.div`
  ${tw('absolute pin-b w-full')};
  transform: matrix(1, 0, 0, -1, 0, 0);
`;

const InnerWave = styled.div`
  ${tw('relative h-full')};
  svg {
    width: 100%;
    height: 40vh;
  }
`;

const AboutHero = styled.div`
  ${tw('flex flex-col lg:flex-row items-center mt-8')};
`;

const Avatar = styled.img`
  ${tw('rounded-none w-32 xl:w-48 shadow-lg h-full')};
`;

const AboutSub = styled.span`
  ${tw('text-white pt-12 lg:pt-0 lg:pl-12 text-2xl lg:text-3xl xl:text-4xl')};
`;

const AboutDesc = styled.p`
  ${tw('text-grey-light text-lg md:text-xl lg:text-2xl font-sans pt-6 md:pt-12 text-justify')};
`;

const ContactText = styled.p`
  ${tw('text-grey-light font-sans text-xl md:text-2xl lg:text-3xl')};
  a {
    color: #e07628;
    text-decoration: none;
  }
`;

const Footer = styled.footer`
  ${tw('text-center text-grey absolute pin-b p-6 font-sans text-md lg:text-lg')};
  a {
    color: #e07628;
    text-decoration: none;
  }
`;

const Index = ({ data: { p1, p2, p3, p4, p5, p6, pp1, pp2, pp3, pp4, pp5, pp6, pw1, pw2, name, email, inquiryType} }) => (
  <React.Fragment>
    <SEO />
    {/*Parallax pages are the number of 100% w/h sections.*/}
    {/*<Parallax pages={7}>*/}
    <Parallax pages={8}>
      {/*Title and Crit*/}
      {/*Divider offset is an index for which parallax page this section comes after. */}
      <Divider speed={1.4} offset={0}>
        <UpDown>
          <SVG icon="triangle" className={hidden} width={48} stroke={colors.orange} left="10%" top="20%" />
          <SVG icon="hexa" width={48} stroke={colors.red} left="60%" top="70%" />
          <SVG icon="box" width={6} fill={colors['grey-darker']} left="60%" top="15%" />
        </UpDown>
        <DividerMiddle
        bg="linear-gradient(to right, Red 0%, #260D09 100%)"
        speed={1.5}
        offset={0.5}
        factor={1}
        />
        <UpDownWide>
          <SVG icon="arrowUp" className={hidden} width={16} fill={colors['red-dark']} left="80%" top="10%" />
          <SVG icon="triangle" width={12} stroke={colors.white} left="90%" top="50%" />
          <SVG icon="upDown" className={hidden} width={8} fill={colors['grey-darkest']} left="45%" top="10%" />
        </UpDownWide>
        <SVG icon="circle" className={hidden} width={24} fill={colors['grey-darker']} left="5%" top="70%" />
        <SVG icon="upDown" className={hidden} width={24} fill={colors['grey-darker']} left="40%" top="80%" />
        <SVG icon="triangle" width={8} stroke={colors['grey-darker']} left="25%" top="5%" />
        <SVG icon="circle" width={64} fill={colors.green} left="95%" top="5%" />
        <SVG icon="box" className={hidden} width={64} fill={colors.purple} left="5%" top="90%" />
        {/*<SVG icon="box" width={6} fill={colors['grey-darkest']} left="10%" top="10%" />*/}
        <SVG icon="box" width={12} fill={colors['grey-darkest']} left="40%" top="30%" />
        <SVG icon="hexa" width={16} stroke={colors['grey-darker']} left="10%" top="50%" />
        <SVG icon="hexa" width={8} stroke={colors['grey-darker']} left="80%" top="70%" />
      </Divider>
      <Content speed={1} offset={0}>
        <Hero>
          <BigTitle>
            Welcome <br /> to the hangar.
          </BigTitle>
          <Subtitle>My name is Tyler.</Subtitle>
          <Subtitle>This is where my projects go post-deployment.</Subtitle>
        </Hero>
      </Content>

      {/*Websites*/}
      <Divider speed={1} offset={1} factor={1}>
        <UpDownWide>
          <SVG icon="upDown" width={8} fill={colors.teal} left="5%" top="10%" />
          <SVG icon="hexa" width={48} stroke={colors.red} left="5%" top="80%" />
          <SVG icon="circle" className={hidden} width={24} fill={colors.darkblue} left="17%" top="60%" />
          <SVG icon="box" width={6} fill={colors.white} left="95%" top="55%" />
          <SVG icon="triangle" width={8} stroke={colors.orange} left="95%" top="5%" />
        </UpDownWide>
        <SVG icon="triangle" className={hidden} width={48} stroke={colors.orange} left="10%" top="20%" />
        {/*<SVG icon="hexa" width={48} stroke={colors.red} left="60%" top="70%" />*/}
        <SVG icon="box" width={48} fill={colors['blue']} left="60%" top="15%" />
      </Divider>
      {/*<Divider bg="#23262b" clipPath="polygon(0 16%, 100% 4%, 100% 82%, 0 94%)" speed={1} offset={1} factor={2} />*/}
      <Content speed={1} offset={0.9} factor={1.5}>
        <Divider bg="#23262b" clipPath="polygon(0 16%, 100% 4%, 100% 82%, 0 94%)" speed={1.4} offset={0.9} factor={1.5}>
          <UpDown>
            <SVG icon="triangle" className={hidden} width={48} stroke={colors.orange} left="10%" top="20%" />
            <SVG icon="hexa" width={48} stroke={colors.red} left="60%" top="70%" />
            <SVG icon="box" width={48} fill={colors['blue']} left="60%" top="15%" />
          </UpDown>
        </Divider>
      </Content>
      <Content speed={1} offset={1} factor={3}>
        <Hero>
        <Inner>
          <Title>Websites</Title>
          {/*Websites*/}
          <ProjectsWrapper>
            <ProjectCard
              fluid={p1.childImageSharp.fluid}
              title="TungstenRoyce.com"
              alt="Tungsten Royce - Custom Wood products"
              link="https://tungstenroyce.com"
            />
            <ProjectCard
              fluid={p2.childImageSharp.fluid}
              title="RCAOC.org"
              alt="Realtor's Commercial Alliance of Orange County"
              link="https://www.rcaoc.org"
            />
            <ProjectCard
              fluid={p3.childImageSharp.fluid}
              title="aCaneAndWickerFixer.com"
              alt="A Cane and Wicker Fixer"
              link="https://www.acaneandwickerfixer.com"
            />
            <ProjectCard
              fluid={p4.childImageSharp.fluid}
              title="GidsRaps.com"
              alt="Gids Raps"
              link="https://www.gidsraps.com"
            />
            <ProjectCard
              fluid={p5.childImageSharp.fluid}
              title="MultimediaArtboard.com"
              alt="Multimedia Artboard"
              link="https://www.multimediaartboard.com"
            />
            <ProjectCard
              fluid={p6.childImageSharp.fluid}
              title="ChaseFordGallery.com"
              alt="Chase Ford Gallery"
              link="http://www.chasefordgallery.com"
            />
          </ProjectsWrapper>
        </Inner>
        </Hero>
      </Content>

      {/*Photography*/}
      <Content speed={1} offset={3} factor={3}>
        <Divider speed={1} offset={1} factor={3}>
          <UpDownWide>
            <SVG icon="box" width={6} fill={colors.white} left="85%" top="75%" />
            <SVG icon="upDown" width={8} fill={colors.teal} left="20%" top="20%" />
            <SVG icon="triangle" width={8} stroke={colors.orange} left="25%" top="5%" />
            <SVG icon="circle" className={hidden} width={24} fill={colors.darkblue} left="17%" top="60%" />
            <SVG icon="triangle" width={16} fill={colors['teal-light']} left="30%" top="95%" />
          </UpDownWide>
          <SVG icon="circle" width={6} fill={colors.white} left="4%" top="20%" />
          <SVG icon="circle" width={12} fill={colors['grey-darkest']} left="70%" top="60%" />
          <SVG icon="hexa" width={8} stroke={colors['grey-darkest']} left="80%" top="70%" />
        </Divider>
        <Hero>
        <Inner>
          <Title>Product Photography</Title>
          <ProjectsWrapper>
            <PhotoCard
              fluid={pp1.childImageSharp.fluid}
              alt="Blue carriage garage doors on rustic cottage home."
            />
            <PhotoCard
              fluid={pp2.childImageSharp.fluid}
              alt="Mediterranean garage door. Shot taken from above. Shown with lamp."
            />
            <PhotoCard
              fluid={pp3.childImageSharp.fluid}
              alt="Blue carriage garage doors. Door open to show handleset."
            />
            <PhotoCard
              fluid={pp4.childImageSharp.fluid}
              alt="Mediterranean garage doors on pueblo style home."
            />
            <PhotoCard
              fluid={pp5.childImageSharp.fluid}
              alt="Mediterranean carriage garage door on modern home."
            />
            <PhotoCard
              fluid={pp6.childImageSharp.fluid}
              alt="Modern contemporary carriage garage door cut from joubert mahogany."
            />
          </ProjectsWrapper>
        </Inner>
        </Hero>
      </Content>

      {/*Videos-----------------------------------------------------------------------------*/}
      <Divider speed={2} offset={4} factor={3}>
        <DividerMiddle
          bg="linear-gradient(to left, Red 0%, #260D09 100%)"
          speed={1}
          offset={4}
          factor={2}
        />
        <UpDownWide>
          <SVG icon="box" width={6} fill={colors.white} left="85%" top="75%" />
          <SVG icon="upDown" width={8} fill={colors.teal} left="70%" top="20%" />
          {/*<SVG icon="triangle" width={8} stroke={colors.orange} left="25%" top="5%" />*/}
          <SVG icon="circle" className={hidden} width={24} fill={colors.darkblue} left="17%" top="60%" />
        </UpDownWide>
        <SVG icon="triangle" width={48} stroke={colors.darkblue} left="10%" top="30%" />
        <SVG icon="hexa" width={48} stroke={colors.orange} left="7%" top="50%" />
        {/*<SVG icon="box" width={48} fill={colors['blue']} left="70%" top="70%" />*/}
      </Divider>
      <Content speed={1} offset={4} factor={2}>
        {/*<Divider speed={1.4} offset={2.9} factor={1.9}>*/}
        <Divider speed={1} offset={4} factor={2}>
          <UpDown>
            <SVG icon="triangle" className={hidden} width={48} stroke={colors.yellow} left="60%" top="10%" />
            {/*<SVG icon="upDown" width={32} fill={colors['blue']} left="30%" top="20%" />*/}
            <SVG icon="hexa" width={24} stroke={colors.green} left="50%" top="40%" />
            {/*<SVG icon="box" width={48} fill={colors['blue']} left="60%" top="60%" />*/}
            <SVG icon="arrowUp" width={16} fill={colors['blue']} left="75%" top="85%" />
            <SVG icon="triangle" width={16} fill={colors['teal-light']} left="30%" top="95%" />
          </UpDown>
        </Divider>
      </Content>
      {/*<Content speed={1} offset={4} factor={1}>*/}
      <Content speed={1} offset={5.5} factor={1}>
        <Hero>
          <Inner>
            <Title>Product Videos</Title>
            {/*Videography*/}
            {/*<ProjectsWrapper>*/}
            <YoutubeWrapper>
              <YoutubeCard
              src="https://www.youtube-nocookie.com/embed/7aKPI9s0z8k?rel=0"
              />
              <YoutubeCard
              src="https://www.youtube-nocookie.com/embed/Pd6LDGuUOdc?rel=0"
              />
            </YoutubeWrapper>
            {/*</ProjectsWrapper>*/}
          </Inner>
        </Hero>
      </Content>
      {/*Print advertising------------------------------------------------------------------------*/}
      {/*Blue Handout Gradient*/}
      <Divider offset={4}>
        <DividerMiddle
          bg="linear-gradient(to left, #255680 0%, #090d26 100%)"
          speed={2}
          offset={5}
          factor={1}
        />
      </Divider>
      <Divider speed={1} offset={4} factor={3}>
        <UpDownWide>
          <SVG icon="arrowUp" className={hidden} width={16} fill={colors.green} left="20%" top="90%" />
          <SVG icon="circle" width={16} fill={colors.yellow} left="70%" top="90%" />
          <SVG icon="triangle" className={hidden} width={16} stroke={colors.teal} left="18%" top="75%" />
          <SVG icon="upDown" className={hidden} width={8} fill={colors.green} left="45%" top="10%" />
        </UpDownWide>
        <SVG icon="circle" width={6} fill={colors.white} left="4%" top="20%" />
        <SVG icon="box" width={12} fill={colors.yellow} left="29%" top="26%" />
        <SVG icon="hexa" width={16} stroke={colors.red} left="75%" top="30%" />
        {/*<SVG icon="hexa" width={8} stroke={colors.yellow} left="80%" top="70%" />*/}
      </Divider>
      <Content speed={1} offset={6} factor={2}>
        <Divider speed={1.4} offset={4} factor={2}>
          <UpDown>
            <SVG icon="triangle" className={hidden} width={48} stroke={colors.yellow} left="60%" top="10%" />
            <SVG icon="upDown" width={32} fill={colors['blue']} left="30%" top="20%" />
            <SVG icon="hexa" width={24} stroke={colors.green} left="50%" top="40%" />
            <SVG icon="box" width={48} fill={colors['blue']} left="60%" top="60%" />
            <SVG icon="arrowUp" width={16} fill={colors['blue']} left="75%" top="85%" />
            <SVG icon="triangle" width={16} fill={colors['teal-light']} left="30%" top="95%" />
          </UpDown>
        </Divider>
      </Content>
      <Content speed={1} offset={6} factor={2}>
        <Hero>
          <Inner>
            <Title>Print Advertising</Title>
            {/*Print Works*/}
            <ProjectsWrapper>
              <PhotoCard
                fluid={pw1.childImageSharp.fluid}
                alt="Catch all advertisement for Tungsten Royce."
              />
              <PhotoCard
                fluid={pw2.childImageSharp.fluid}
                alt="Carriage door advertisement for Tungsten Royce featuring signature."
              />
            </ProjectsWrapper>
          </Inner>
        </Hero>
      </Content>
      <Divider speed={1} offset={5} factor={3}>
        <UpDownWide>
          <SVG icon="arrowUp" className={hidden} width={16} fill={colors.green} left="20%" top="90%" />
          <SVG icon="circle" width={16} fill={colors.yellow} left="70%" top="90%" />
          <SVG icon="triangle" className={hidden} width={16} stroke={colors.teal} left="18%" top="75%" />
          <SVG icon="upDown" className={hidden} width={8} fill={colors.green} left="45%" top="10%" />
        </UpDownWide>
        <SVG icon="circle" width={6} fill={colors.white} left="4%" top="20%" />
        <SVG icon="box" width={12} fill={colors.yellow} left="29%" top="26%" />
        <SVG icon="hexa" width={16} stroke={colors.red} left="75%" top="30%" />
        {/*<SVG icon="hexa" width={8} stroke={colors.yellow} left="80%" top="70%" />*/}
      </Divider>

      {/*Footer Section----------------------------------------------------------------------*/}
      <Divider fill="#ff0030" speed={1} offset={7}>
        <WaveWrapper>
          <InnerWave>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 338.05" preserveAspectRatio="none">
              <path className={waveAnimation}>
                <animate
                  attributeName="d"
                  values="M 0 100 Q 250 50 400 200 Q 550 350 800 300 L 800 0 L 0 0 L 0 100 Z;M 0 100 Q 200 150 400 200 Q 600 250 800 300 L 800 0 L 0 0 L 0 100 Z;M 0 100 Q 150 350 400 200 Q 650 50 800 300 L 800 0 L 0 0 L 0 100 Z"
                  repeatCount="indefinite"
                  dur="40s"
                />
              </path>
            </svg>
          </InnerWave>
        </WaveWrapper>
      </Divider>
      <Content speed={1} offset={7}>
        <Hero>
        <Inner>
          <Title>Looking to start a project?<br />Let's talk.</Title>
          {/*<Contact></Contact>*/}
          {/*<ContactForm name={name} email={email} inquiryType={inquiryType}></ContactForm>*/}
          <ContactText>
            Start the conversation with an <a href="mailto:tyler@httphangar.com">email.</a>{' '}<br/>
            Resume available upon request.
          </ContactText>
        </Inner>
        </Hero>
        <Footer>
          &copy; 2018 - Tyler H
        </Footer>
      </Content>
      <Divider speed={1} offset={5}>
        <UpDown>
          <SVG icon="upDown" className={hidden} width={8} fill={colors['grey-darkest']} left="70%" top="20%" />
          <SVG icon="triangle" width={8} stroke={colors['grey-darkest']} left="25%" top="5%" />
        </UpDown>
        {/*<UpDownWide>*/}
          {/*<SVG icon="triangle" width={12} stroke={colors.white} left="95%" top="50%" />*/}
          {/*<SVG icon="circle" width={6} fill={colors.white} left="85%" top="15%" />*/}
          {/*<SVG icon="upDown" className={hidden} width={8} fill={colors['grey-darkest']} left="45%" top="10%" />*/}
        {/*</UpDownWide>*/}
        <SVG icon="circle" width={6} fill={colors.white} left="4%" top="20%" />
        <SVG icon="circle" width={12} fill={colors['grey-darkest']} left="70%" top="60%" />
        <SVG icon="hexa" width={8} stroke={colors['grey-darkest']} left="80%" top="70%" />
      </Divider>
    </Parallax>
  </React.Fragment>
);

export default Index;

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

/* eslint no-undef: "off" */
export const query = graphql`
  query IndexQuery {
    p1: file(relativePath: { regex: "/web/project_1.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 800, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    p2: file(relativePath: { regex: "/web/project_2.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 800, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    p3: file(relativePath: { regex: "/web/project_3.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 800, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    p4: file(relativePath: { regex: "/web/project_4.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 800, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    p5: file(relativePath: { regex: "/web/project_5.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 800, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    p6: file(relativePath: { regex: "/web/project_6.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 800, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    pp1: file(relativePath: { regex: "/photography/photo_1.jpg/" }) {
      childImageSharp { 
        fluid(maxWidth: 800, maxHeight: 535, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    pp2: file(relativePath: { regex: "/photography/photo_2.jpg/" }) {
      childImageSharp { 
        fluid(maxWidth: 800, maxHeight: 535, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    pp3: file(relativePath: { regex: "/photography/photo_3.jpg/" }) {
      childImageSharp { 
        fluid(maxWidth: 800, maxHeight: 535, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    pp4: file(relativePath: { regex: "/photography/photo_4.jpg/" }) {
      childImageSharp { 
        fluid(maxWidth: 800, maxHeight: 535, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    pp5: file(relativePath: { regex: "/photography/photo_5.jpg/" }) {
      childImageSharp { 
        fluid(maxWidth: 800, maxHeight: 535, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    pp6: file(relativePath: { regex: "/photography/photo_6.jpg/" }) {
      childImageSharp { 
        fluid(maxWidth: 800, maxHeight: 535, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    pw1: file(relativePath: { regex: "/prints/print_1.jpg/" }) {
      childImageSharp { 
        fluid(maxWidth: 665, maxHeight: 800, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    pw2: file(relativePath: { regex: "/prints/print_2.jpg/" }) {
      childImageSharp { 
        fluid(maxWidth: 665, maxHeight: 800, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;
