/* global tw */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import Img from 'gatsby-image';
// import Link from 'gatsby-link';

const Wrapper = styled.a`
  width: 100%;
  ${tw('shadow-lg relative no-underline')};
  // img {
  //   transition: all 0.6s ease-in-out !important;
  //   filter: grayscale(0);
  // }
  // &:hover {
  //   img {
  //     transform: scale(1.15);
  //     filter: grayscale(0);
  //   }
  // }
  // @media (min-width: 900px) {
  //   img {
  //     filter: grayscale(1);
  //   }
  // }
`;

const Image = styled.div`
  ${tw('overflow-auto')};
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const PhotoCard = ({ fluid, alt }) => (
  <Wrapper>
    <Image>
      <Img fluid={fluid} alt={alt} />
    </Image>
  </Wrapper>
);

export default PhotoCard;

PhotoCard.propTypes = {
  fluid: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired,
};
